//= require bootstrap-colorpicker

import $ from 'jquery'
window.$ = window.jQuery = window.jquery = $

// import 'chosen-js/chosen.css'
// import 'chosen-js/chosen.jquery'

require ("moment")

import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import fullCalendarFrLocale from '@fullcalendar/core/locales/fr'; 

function eventCalendar() {
    var calendarEl = document.getElementById('calendar');
    var calendar = new Calendar(calendarEl, {
      plugins: [ dayGridPlugin, timeGridPlugin ],
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth timeGridWeek'
      },
      events: calendarEl.getAttribute('data-events-url'),
      locale: fullCalendarFrLocale
    });
    calendar.render();
};

window.App || (window.App = {});

App.init = function() {
  $("[rel='tooltip']").tooltip();
  $("[rel='popover']").popover();
  $(".conversations-show a[href^='http://']").attr("target","_blank");
  $("a[confirm]").on('click', function (e) {
    return confirm(e.target.attributes['confirm'].value);
  });

  if (typeof tinyMCE !== 'undefined') {
    tinyMCE.init({
      selector: 'textarea.tinymce',
      language: 'fr_FR',
      content_css: '/tinymce.css',
      toolbar: 'bold italic underline strikethrough separator bullist numlist separator cut copy paste pastetext pasteword separator spellchecker',
      plugins: 'paste autoresize',
      browser_spellcheck: true,
      menubar: false,
      statusbar: false
    });
  }

  if (document.getElementById('calendar')) {
    eventCalendar()
  }

  $("#content form").on('submit', function() {
    $(this).find("input[type='submit']").attr('disabled', 'disabled');
    if ($(this).find("#message_draft") && $(this).find("#message_draft").first().val() == "true")
      $(this).find("input[type='submit']").attr('value', 'Envoyer');
    else
      $(this).find("input[type='submit']").attr('value', 'En cours...');
  });

  $('i.star').on('click', function() {
    $(this).toggleClass('icon-star-empty');
    $(this).toggleClass('icon-star');
  });

  $('.new-comment-button').on('click', function () {
    $(this).hide();
    $($(this).attr('data-reply-id')+'-comment-form').show();
  });
  true
};

$(document).on('turbolinks:load', function() {
  App.init();
});
